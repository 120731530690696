/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnChanges,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { SignUpErrorFormatter } from '@sybl/common-models';
import { MatFormErrorDumbComponent } from './mat-form-error-dumb.component';
/**
 * Component is used on custom validators to be able to show more than one of the errors at a time.
 */
export class MatFormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'lib-mat-form-error-component',
  standalone: true,
  imports: [CommonModule, MatFormErrorDumbComponent],
  templateUrl: './mat-form-error.component.html',
  styleUrls: ['./mat-form-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatFormErrorComponent implements OnInit, OnChanges {
  @Input() inputControl!: FormControl | any;
  @Input() props: any;

  @Output() errorChanged = new EventEmitter();

  errorText: string;

  errors: Array<string> = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(change: SimpleChanges): void {
    // When <mat-form-error> is applied to a reactive form when listening to error changes, get one at a timeFook
    if (change['inputControl']) {
      const errorInput = change['inputControl'].currentValue;

      if (errorInput !== null) {
        this.errors = [];

        const controlError = Object.getOwnPropertyNames(this.inputControl);
        controlError.map((err) => {
          const errorName = err;
          const formattedErr: any = new SignUpErrorFormatter().findError(
            errorName,
            this.props
          );

          this.errors.push(formattedErr);
        });
      }else this.errors = [];
    }
  }

  ngOnInit() {
    return;
  }
}
